import React from 'react';
import styles from './../../css/ProductsPage.module.css';
import associationImage from "./../../img/AssociationsImage.png"

export const AssociationSection = () => {
  return (
    <section className={styles.associationSection}>
      <div className={styles.associationContent}>
        <img src={associationImage} alt="" className={styles.associationImage} />
        <div className={styles.associationText}>
          <h2 className={styles.associationTitle}>Udruge</h2>
          <p className={styles.associationDescription}>
            Moja Udruga cjelovito je rješenje osmišljeno kao pomoć udrugama u njihovim administrativnim poslovima.
            Od upravljanja evidencijom članova i zdravstvenih pregleda do praćenja članarina, ovaj alat pojednostavljuje
            svakodnevno upravljanje voditeljima udruga. Dostupan besplatno svim udrugama registriranim u Hrvatskoj,
            nudi platformu jednostavnu za korištenje koja osigurava nesmetan i učinkovit rad.
          </p>
          <a href="https://www.udruga.online" className={styles.associationLink}>
            Registrirajte se danas na https://www.udruga.online
          </a>
        </div>
      </div>
    </section>
  );
};