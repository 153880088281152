import React from 'react';
import styles from './../../css/ProductsPage.module.css';
import audioImage from './../../img/AudioImage.png'

export const AudioSection = () => {
  return (
    <section className={styles.audioSection}>
      <div className={styles.audioContent}>
        <div className={styles.audioInfo}>
          <h2 className={styles.audioTitle}>Audio player</h2>
          <p className={styles.audioDescription}>
            Audio player omogućava pretvorbu pisanih članaka u govor.
          </p>
          <button className={styles.audioButton}>Pošalji upit</button>
        </div>
        <div className={styles.audioDemo}>
          <img src={audioImage} alt="" className={styles.audioDemoImage} />
          <p className={styles.audioDemoText}>
            Prednost ove aplikacije je što ne zahtjeva ljudski glas već automatski prebacuje pisani tekst u govor,
            bez ljudskog posredovanja. Sustav podržava više od 50 jezika te njihove varijacije te pruža mogućnost
            odabira glasa čitatelja.
          </p>
        </div>
      </div>
    </section>
  );
};