import React from 'react';
import styles from './../css/ContactPage.module.css';
import {LocationOn, Phone, Email} from '@mui/icons-material';
import Navigation from "./Navigation";
import Footer from "./Footer";

function ContactPage() {
    return (
        <div className={styles.contactPage}>
            <Navigation/>
            <div className={styles.hero}>
                <h1 className={styles.heroTitle}>Kontaktirajte nas</h1>
                <p className={styles.heroSubtitle}>Obratite nam se s Povjerenjem!</p>
            </div>
            <div className={styles.contentContainer}>
                <h2 className={styles.sectionTitle}>Tu smo za vas</h2>
                <p className={styles.sectionText}>
                    Naš tim stručnjaka za programiranje, dizajn i IT savjetovanje tu je kako bi vas podržao na svakom
                    koraku vašeg digitalnog puta. Javite nam se putem emaila, telefona ili nas posjetite u našem uredu –
                    radujemo se suradnji!
                </p>

                <div className={styles.infoContainer}>
                    <div className={styles.contactBox}>
                        <h3 className={styles.boxTitle}>Posjetite nas u našem uredu!</h3>
                        <div className={styles.boxContent}>
                            <p className={styles.location}><strong>Karlovac, Hrvatska</strong></p>
                            <div className={styles.contactItem}>
                                <LocationOn className={styles.icon}/>
                                <span>Mekušanska cesta 5, 47000 Karlovac</span>
                            </div>
                            <div className={styles.contactItem}>
                                <Phone className={styles.icon}/>
                                <span>+385 91 128 0108</span>
                            </div>
                            <div className={styles.contactItem}>
                                <Email className={styles.icon}/>
                                <span>info@itcs.hr</span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.mapContainer}>
                        <iframe
                            title="IT Consulting Services Location"
                            src="
                            https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.268879752055!2d15.570089013181507!3d45.48452997095375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47643d5fab20e6c7%3A0xc3d64eddadaa9087!2sIT%20Consulting%20Services%20d.o.o.!5e0!3m2!1shr!2sba!4v1731325401698!5m2!1shr!2sba"
                            width="100%"
                            height="300"
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
                <div className={styles.companyInfo}>
                    <p>
                        * IT Consulting Services d.o.o.
                        Stjepana Radića 18 HR-47000 Karlovac
                        Trgovačko društvo upisano je u Sudski registar Trgovačkog suda u Zagrebu (SS Karlovac) pod
                        matičnim brojem subjekta 060141866
                        Temeljni kapital: 36.259,87 euro, uplaćen u cijelosti.
                        IBAN: HR2124840081101323324 kod Raiffeisenbank Austria d.d.
                        OIB: 42237961602
                        Član uprave: Branko Radojević
                        Telefon: +385 91 128 1064
                    </p>
                </div>
            </div>
            <Footer/>

        </div>
    );
}

export default ContactPage;
