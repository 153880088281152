import React from 'react';
import styles from './../../css/ProductsPage.module.css';
import {FeatureCard} from './FeatureCard';
import Footer from './../Footer';
import {AssociationSection} from "./AssociationsSection";
import {AudioSection} from "./AudioSection";
import productsHero from "./../../img/Products Hero.png"
import vehicles from "./../../img/projekti-web.jpg"
import vacation from "./../../img/godisnji-web.jpg"
import travel from "./../../img/putni-web.jpg"
import reminders from "./../../img/podsjetniciv2y.jpg"
import Navigation from "../Navigation";

const features = [
    {
        icon: vacation,
        title: 'Godišnji odmori',
        description: 'Ovaj modul omogućuje zaposlenicima brz i jednostavan pregled dana godišnjeg odmora...'
    },
    {
        icon: vehicles,
        title: 'Projekti',
        description: 'Pomaže u praćenju zadataka, služi kao podsjetnik i nudi uvid u trenutni status...'
    },
    {
        icon: travel,
        title: 'Putni nalozi',
        description: 'Digitalizira proces od zahtjeva za službeno putovanje do zaključenja izvještaja...'
    },
    {
        icon: reminders,
        title: 'Podsjetnici',
        description: 'Automatski integrira zadatke u kalendar, uključuje praznike i vlastite podsjetnike...'
    }
];

export const LandingPage = () => {
    return (
        <>
            <Navigation/>
            <div className={styles.landingPage}>
                <section className={styles.heroSection}>
                    <img src={productsHero} alt="" className={styles.heroBg}/>
                    <div className={styles.heroContent}>
                        <h1 className={styles.heroTitle}>Upoznajte naše proizvode</h1>
                        <p className={styles.heroSubtitle}>
                            Programiramo inovativna rješenja za optimizaciju i unapređenje vašeg poslovanja.
                        </p>
                    </div>
                </section>

                <section className={styles.featureSection}>
                    <h2 className={styles.featureTitle}>Uredski pomoćnik</h2>
                    <p className={styles.featureDescription}>
                        Interni sustav tvrtke koji osigurava da se važni rokovi nikada ne propuštaju te da su ključni
                        podaci
                        uvijek dostupni,
                        ovisno o hijerarhijskim ovlaštenjima. Poboljšava komunikaciju i pojednostavljuje poslovanje.
                    </p>
                    <div className={styles.featureGrid}>
                        {features.map((feature, index) => (
                            <FeatureCard key={index} {...feature} />
                        ))}
                    </div>
                </section>

                <AssociationSection/>
                <AudioSection/>
                <Footer/>
            </div>
        </>
    );
};