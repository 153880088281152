import React from 'react';
import styles from './../../css/OfficeAssistant.module.css';
import FeatureIcon from './FeatureIcon';
import Screenshot from './Screenshot';
import heroImgae from './../../img/OA hero.png'
import noticeBoard from './../../img/oglasna-web.jpg'
import documents from './../../img/dokumenti-web.jpg'
import table from './../../img/moj stol-web.jpg'
import vacation from './../../img/godisnji-web.jpg'
import projects from './../../img/projekti-web.jpg'
import base from './../../img/baza znanja-web.jpg'
import vehicles from './../../img/sluzbena-web.jpg'
import workHours from './../../img/radno vrijeme-web.jpg'
import hr from './../../img/kadrovska-web.jpg'
import trip from './../../img/putni-web.jpg'
import meeting from './../../img/e-sast-web.jpg'
import chat from './../../img/chat-web.jpg'
import date from './../../img/terminator-web.png'
import gallery from './../../img/galerija-web.png'
import notification from './../../img/podsjetniciv2y.jpg'
import timeIcon from './../../img/Time icon.png'
import dashboardIcon from './../../img/Dashboard icon.png'
import performanceIcon from './../../img/Performance icon.png'
import {Navigate} from "react-router-dom";
import Navigation from "../Navigation";
import yellowLine from "./../../img/yellow line.png"
import Footer from "../Footer";




const features = [
  {
    imgSrc: timeIcon,
    title: "Praćenje u stvarnom vremenu",
    description: "Pratite sve svoje poslovne procese i bitne podatke u stvarnom vremenu"
  },
  {
    imgSrc: dashboardIcon,
    title: "Personalizacija dashboarda",
    description: "Prilagodite svoj radni prostor tako da odgovara vašim jedinstvenim potrebama."
  },
  {
    imgSrc: performanceIcon,
    title: "Performanse",
    description: "Povećajte produktivnost automatizacijom dijela administracije"
  }
];

const screenshots = [
  {
    title: "Oglasna ploča",
    description: "Dodatni kanal komunikacije unutar tvrtke. Osigurava da važne ili manjevažne informacije samo ne kruže po hodnicima ili se zagube među mailovima.",
    imgSrc: noticeBoard
  },
  {
    title: "Dokumenti",
    description: "Omogućava pohranu i praćenje različitih vrsta dokumenata, uključujući pravilnike, izvještaje, ugovore i ostale relevantne materijale koji čine srž svakodnevnih poslovnih operacija. Sadrži i dio namijenjen novim zaposlenicima.",
    imgSrc: documents
  },
  {
    title: "Moj stol",
    description: "Lako prenošenje svih vrsta datoteka bez ograničenja u veličini.",
    imgSrc: table
  },
  {
    title: "Godišnji odmor",
    description: "Ovaj modul omogućuje zaposlenicima brz i jednostavan pregled statusa njihovih dana te nudi mogućnost automatskog generiranja rješenja za odobravanje godišnjeg odmora.",
    imgSrc: vacation
  },
  {
    title: "Projekti",
    description: "Pomaže u lakšem praćenju zadataka. Služi kao podsjetnik za zadatke koje treba riješiti i daje jednostavan uvid u trenutni status pojedinog zadataka.",
    imgSrc: projects
  },
  {
    title: "Baza znanja",
    description: "Zadržite vrijedno znanje unutar svoje tvrtke. Ovaj modul bilježi najbolje prakse i rješenja, osiguravajući da stručnost ostane unutar organizacije, čak i kada zaposlenici nisu prisutni.",
    imgSrc: base
  },
  {
    title: "Službena vozila",
    description: "Pravovremeno će vas podsjetiti na nadolazeće aktivnosti koje uključuju registraciju, osiguranje, zamjenu guma, održavanje ili bilo koji specifičan element koji ste sami zadali.",
    imgSrc: vehicles
  },
  {
    title: "Radno vrijeme",
    description: "Detaljan pregled evidentiranih radnih sati. Prikazuje aktivnost pojedinog zaposlenika u realnom vremenu za tekući radni dan, no ima i mogućnost automatizacije za potrebe slanja u knjigovodstvo.",
    imgSrc: workHours
  },
  {
    title: "Kadrovska",
    description: "Središnje mjesto za sve informacije o zaposlenicima i suradnicima tvrtke koje ima mogućnosti postavljanja podsjetnika na istek ugovora, certifikata, radnih dozvola i sl.",
    imgSrc: hr
  },
  {
    title: "Putni nalozi",
    description: "Omogućava potpunu digitalizaciju procesa od podnošenja zahtjeva za službeno putovanje do zaključenja izvještaja.",
    imgSrc: trip
  },
  {
    title: "E sastanci",
    description: "Osigurava organizaciju i praćenje sastanka od poziva do zapisnika te po svim fazama između kao što su davanje suglasnosti, elektronsko glasanje i pretvaranje audio zapisa u tekst.",
    imgSrc: meeting
  },
  {
    title: "Ćakulator",
    description: "Brza komunikacija bez ometanja oglasima i neželjenim sadržajem putem mobilne i desktop aplikacije.",
    imgSrc: chat
  },
  {
    title: "Terminator",
    description: "Jednostavno dogovaranje datume koji najbolje odgovaraju većini. U značajnoj mjeri skraćuje vrijeme potrebno za koordinaciju sastanaka ili drugih događanja.",
    imgSrc: date
  },
  {
    title: "Galerija",
    description: "Osigurava da fotografije s teambuilding i poslovnih seminara zauvijek ostanu uz Vas i Vaše kolege.",
    imgSrc: gallery
  },
  {
    title: "Podsjetnici",
    description: "Automatski integrira važne zadatke iz drugih modula u kalendar. Uključuje i državne praznike te omogućuje dodavanje vlastitih podsjetnika. Također, može se prikazati kao lista za jednostavniji pregled.",
    imgSrc: notification
  }
];



function OfficeAssistant() {


  return (
      <>
        <Navigation/>
        <main className={styles.officeAssistant}>
          <div className={styles.container}>
            <section className={styles.content}>
              <div className={styles.textColumn}>
                <div className={styles.textBlock}>
                  <div className={styles.text}>
                    <h1 className={styles.title}>Uredski pomoćnik</h1>
                    <p className={styles.description}>
                      Upoznajte našeg uredskog pomoćnika – pametno rješenje dizajnirano za optimizaciju Vašeg
                      poslovanja. Uz obavijesti s kojima je nemoguće propustiti važne rokove, kontinuiranu dostupnost
                      svih bitnih podataka te automatizaciju administracije, Uredski je pomoćnik vrhunski alat za
                      moderno poslovno upravljanje.
                    </p>
                  </div>
                  <button className={styles.ctaButton}>Zatražite demo</button>
                </div>
              </div>
              <div className={styles.imageColumn}>
                <img src={heroImgae} alt="Uredski pomoćnik" className={styles.heroImage}/>
              </div>

            </section>
            <section className={styles.mainContent}>
              <div className={styles.features}>
                <div className={styles.featureIcons}>
                  {features.map((feature, index) => (
                      <FeatureIcon key={index} {...feature} />
                  ))}
                </div>
              </div>
              <div className={styles.screenshots}>
                {screenshots.map((screenshot, index) => (
                    <Screenshot key={index} {...screenshot} isReversed={index % 2 !== 0}/>
                ))}
              </div>
            </section>
          </div>
        </main>
        <Footer/>
      </>
  );
}

export default OfficeAssistant;