import React from 'react';
import ServiceCard from './ServiceCard';
import styles from './../css/ServicesSection.module.css';
import ComputerIcon from '@mui/icons-material/Computer';
import DevicesIcon from '@mui/icons-material/Devices';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

const servicesDataColumn1 = [
  {
    IconComponent: ComputerIcon,
    title: "Programiranje",
    description: "Razvijamo kompleksna i sigurna softverska rješenja za unapređenje Vašeg poslovanja."
  },
  {
    IconComponent: DevicesIcon,
    title: "Digitalni proizvodi",
    description: "Stvaramo digitalne proizvode koji optimiziraju vaše poslovne procese i pojednostavljuju tijek rada."
  },
  {
    IconComponent: DesignServicesIcon,
    title: "Web dizajn",
    description: "Izrada vizualno privlačnih web stranica koje su korisnički usmjerene te prilagođene vašem brendu i ciljanoj publici."
  }
];

const servicesDataColumn2 = [
  {
    IconComponent: QuestionAnswerIcon,
    title: "Savjetovanje",
    description: "Naše strateško informatičko savjetovanje pomaže vam da pojednostavite svoje poslovne procese."
  },
  {
    IconComponent: ViewSidebarIcon,
    title: "Produkt dizajn",
    description: "Naše strateško informatičko savjetovanje pomaže vam da pojednostavite svoje poslovne procese."
  },
  {
    IconComponent: DeveloperModeIcon,
    title: "Mobilne aplikacije",
    description: "Naše strateško informatičko savjetovanje pomaže vam da pojednostavite svoje poslovne procese."
  }
];

const ServicesSection = () => (
  <section className={styles.servicesSection}>
    <h2 className={styles.sectionTitle}>Naše usluge</h2>
    <h3 className={styles.sectionSubtitle}>Nudimo širok spektar IT usluga</h3>

    <div className={styles.columnsContainer}>
      <div className={styles.column}>
        {servicesDataColumn1.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </div>

      <div className={styles.column}>
        {servicesDataColumn2.map((service, index) => (
          <ServiceCard key={index + servicesDataColumn1.length} {...service} />
        ))}
      </div>
    </div>
  </section>
);

export default ServicesSection;
