import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import PhoneIcon from '@mui/icons-material/Phone';
import {Link} from '@mui/material';
import logo from '../img/itcs-logo.png'; // Učitavanje logotipa

const pages = [
    {name: 'Proizvodi', path: '/products'},
    {name: 'Uredski pomoćnik', path: '/oa'},
    {name: 'Blog', path: '/blog'},
];

function Navigation() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
      <AppBar position="static" sx={{ bgcolor: 'white', color: 'black', boxShadow: 'none' }}>
    <Container maxWidth="xl">
        <Toolbar disableGutters>
            {/* Logo kao slika */}
            <Link href="/" sx={{ flexShrink: 0, display: { xs: 'none', md: 'block' } }}>
                <Box component="img" src={logo} alt="Logo" sx={{ height: 40, mr: 2 }} />
            </Link>

            {/* Responsive Menu Icon */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{ display: { xs: 'block', md: 'none' } }}
                >
                    {pages.map((page) => (
                        <MenuItem key={page.name} onClick={handleCloseNavMenu} component="a" href={page.path}>
                            <Typography sx={{ textAlign: 'center', fontFamily: 'Roboto', fontWeight: 700, fontSize: 14, textTransform: 'none' }}>
                                {page.name}
                            </Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>

            {/* Logo za mobile */}
            <Link href="/" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'center', width: '100%' }}>
                <Box component="img" src={logo} alt="Logo" sx={{ height: 40 }} />
            </Link>

            {/* Links in the center for larger screens */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems: 'center' }}>
                {pages.map((page) => (
                    <Button
                        key={page.name}
                        href={page.path}
                        sx={{
                            my: 2,
                            color: 'black',
                            display: 'block',
                            fontFamily: 'Roboto',
                            fontWeight: 700,
                            fontSize: 14,
                            textTransform: 'none',
                        }}
                    >
                        {page.name}
                    </Button>
                ))}
            </Box>

            {/* Contact button */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    href="/contact"
                    sx={{
                        bgcolor: '#F8D00D',
                        color: 'black',
                        fontFamily: 'Roboto',
                        fontWeight: 500,
                        fontSize: 14,
                        borderRadius: '30px',
                        display: { xs: 'none', md: 'inline-flex' },
                        textTransform: 'none',
                    }}
                >
                    Kontaktirajte nas
                </Button>

                {/* Contact icon for mobile */}
                <IconButton
                    href="/contact"
                    sx={{
                        display: { xs: 'inline-flex', md: 'none' },
                        bgcolor: '#F8D00D',
                        color: 'black',
                        borderRadius: '50%',
                        p: 1,
                        ml: 1,
                    }}
                >
                    <PhoneIcon />
                </IconButton>
            </Box>
        </Toolbar>
    </Container>
</AppBar>

    );
}

export default Navigation;
