import React from 'react';
import FeatureCard from './FeatureCard';
import styles from './../css/FeaturesSection.module.css';

const featuresData = [
  { title: "Profesionalan", description: "pristup" },
  { title: "Predanost", description: "vašem uspjehu" },
  { title: "Inovativno", description: "razmišljanje" },
  { title: "Fokus", description: "Na rezultate" },
  { title: "Učinkovito", description: "Ostvarivanje ciljeva" }
];

const FeaturesSection = () => (
  <section className={styles.featuresSection}>
    <h2 className={styles.sectionTitle}>Zašto odabrati nas?</h2>
    <h3 className={styles.sectionSubtitle}>Nudimo najbolje usluge</h3>
    <div className={styles.featureCards}>
      {featuresData.map((feature, index) => (
        <FeatureCard key={index} {...feature} />
      ))}
    </div>
  </section>
);

export default FeaturesSection;