import React from 'react';
import styles from './../css/FeatureCard.module.css';

const FeatureCard = ({ title, description }) => (
  <div className={styles.featureCard}>
    <div className={styles.featureIcon} />
      <div className={styles.featuresCardText}>
          <h3 className={styles.featureTitle}>{title}</h3>
          <p className={styles.featureDescription}>{description}</p>
      </div>
  </div>
);

export default FeatureCard;