import React from 'react';
import styles from './../css/Footer.module.css';
import logo from './../img/itcs-logo.png';

const navItems = [
  { name: 'Naslovna', path: '/' },
  { name: 'Proizvodi', path: '/products' },
  { name: 'Pomoćnik', path: '/oa' },
  { name: 'Novosti', path: '/blog' },
  { name: 'Kontakt', path: '/contact' }
];

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.logo}>
          <img loading="lazy" src={logo} className={styles.logoIcon} alt="" />
          <br />
          <div className={styles.logoText}>IT Consulting Services d.o.o.</div>
        </div>
        <nav className={styles.navigation}>
          {navItems.map((item) => (
            <a key={item.name} href={item.path}>{item.name}</a>
          ))}
        </nav>
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/9c40f9a9217412202e85bfc37da1fa362f92f61ffa1b721a8f7cb54695feb589?placeholderIfAbsent=true&apiKey=de87054b713149a7a6392d770b91d54c" className={styles.divider} alt="" />
        <div className={styles.copyright}>2024 @copyright IT Consulting Services d.o.o. - sva prava pridržana</div>
      </div>
    </footer>
  );
}

export default Footer;
