import React from 'react';
import styles from './../css/ProductSection.module.css';
import OAImage from "./../img/OA image.png"

const ProductSection = () => (
  <section className={styles.productSection}>
    <div className={styles.productContent}>
      <h2 className={styles.productTitle}>Upoznajte jedinstveni proizvod</h2>
      <h3 className={styles.productSubtitle}>Uredski pomoćnik</h3>
      <p className={styles.productDescription}>
        Upoznajte našeg uredskog pomoćnika – pametno rješenje dizajnirano za optimizaciju Vašeg poslovanja. Uz obavijesti s kojima je nemoguće propustiti važne rokove, kontinuiranu dostupnost svih bitnih podataka te automatizaciju administracije, Uredski je pomoćnik vrhunski alat za moderno poslovno upravljanje
      </p>
      <button className={styles.ctaButton}>Pročitajte više</button>
    </div>
    <div className={styles.productImageContainer}>
      <img loading="lazy" src={OAImage} alt="Uredski pomoćnik interface" className={styles.productImage} />
    </div>
  </section>
);

export default ProductSection;