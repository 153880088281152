import React from 'react';
import styles from './../css/AboutSection.module.css';
import aboutImage from "./../img/About image.png"

const AboutSection = () => (
  <section className={styles.aboutSection}>
    <img loading="lazy" src={aboutImage} alt="Team working together" className={styles.aboutImage} />
    <div className={styles.aboutContent}>
      <h2 className={styles.aboutTitle}>O nama</h2>
      <h3 className={styles.aboutSubtitle}>NA KORAK DO <br/> APLIKACIJE IZ SNOVA</h3>
      <p className={styles.aboutDescription}>
        Uz tim iskusnih stručnjaka, predani smo pružanju izvrsnosti i preciznosti u svakom projektu. Prioritet nam je suradnja i razumijevanje vaših ciljeva kako bismo osigurali uspješne rezultate. Bilo da se radi o kompleksnom softveru ili sveobuhvatnoj IT strategiji, tu smo da podržimo vaše poslovanje na svakom koraku.
      </p>
      <div className={styles.statsContainer}>
        <div className={styles.statItem}>
          <span className={styles.statNumber}>20+</span>
          <span className={styles.statLabel}>Uspješnih projekata</span>
        </div>
        <div className={styles.statItem}>
          <span className={styles.statNumber}>50+</span>
          <span className={styles.statLabel}>Zadovoljnih klijenata</span>
        </div>
      </div>
    </div>
  </section>
);

export default AboutSection;